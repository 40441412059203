import GroupRepository from '@/shared/http/repositories/access/group'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import fab from 'vue-fab'
import Roles from '@/shared/mixins/hasRoles'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    fab
  },

  mixins: [Roles],

  data: () => ({
    groups: [],
    showModalDeleteGroup: false,
    emptyText: 'Não há grupos para serem exibidos',
    teacherGroup: '6b1583ea-38ae-42f4-a881-f05d6ff7c0f1',
    coordinatorGroup: 'bd4a77f0-e318-405e-3952-08da4e268814',
    generalCoordinatorGroup: '8c7f84fd-2f79-4db5-bc9a-4931986f5fae',
    fields: [
      {
        key: 'name',
        label: 'NOME',
        class: 'left-header-border text-center',
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Grupos',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      GroupRepository.GetAll(this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.groups = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar grupos', 'ERRO')
        })
    },

    onDelete() {
      const hasGroup = this.id == this.teacherGroup || this.id == this.coordinatorGroup || this.id == this.generalCoordinatorGroup
      if(hasGroup) {
        this.showModalDeleteGroup = false
        return toast.warning('Não é possível excluir este grupo.', 'GRUPO RESTRITO')
      }

      loading.push()
      GroupRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteGroup = false
          toast.success('Grupo excluído com sucesso!', 'EXCLUSÃO DE GRUPO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir grupo', 'ERRO')
        })
    },

    selectRow(id) {
      this.showModalDeleteGroup = true
      this.id = id
    }
  }
}
